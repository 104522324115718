/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import "firebase/auth";
import 'firebase/firestore';
import "firebase/database";
// If widgets props are missing, use widgetPropsAny
export class TimelineWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectFeedMode: props.selectFeedMode,
      defaultSymbol: props.defaultSymbol,
    };
  }
  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-timeline.js'
    script.async = true;
    script.innerHTML = JSON.stringify(
      {
        "feedMode": this.state.selectFeedMode,
        "symbol": this.state.defaultSymbol,
        "colorTheme": "dark",
        "isTransparent": false,
        "displayMode": "regular",
        "width": localStorage.getItem('width'),
        "height": "600",
        "locale": "en"
      }
    )
    document.getElementById("myContainerTimelineWidget").appendChild(script);
  }
  render() {
    return (
      <>
        <div id="myContainerTimelineWidget">
          <div className="tradingview-widget-container">
            <div className="tradingview-widget-container__widget">
            </div>
          </div>
        </div>
        <div class="tradingview-widget-copyright"><a href="https://www.tradingview.com" rel="noreferrer" target="_blank"><span class="blue-text"> History</span></a> by TradingView</div>
      </>
    );
  }
}
export default TimelineWidget;
