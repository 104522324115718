import { Image, StyleSheet, View, Page, Document, PDFDownloadLink } from '@react-pdf/renderer';
import OptionIdeaTitle from './OptionIdeaTitle'
import BillTo from './OptionIdeaBillTo'
import OptionIdeaNo from './OptionIdeaNo'
import OptionIdeaItemsTable from './OptionIdeaItemsTable'
import OptionIdeaThankYouMsg from './OptionIdeaThankYouMsg'
import Logo from "../../../assets/img/brand/Logo.png"


const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft: 60,
        paddingRight: 60,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
    logo: {
        width: "22%",
        height: "10%",
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    page: {
        flexDirection: 'row',
        backgroundColor: '#ffffff'
    },
});

const ReportPDF = ({ OptionIdea, titleOptionIdea }) => {

    console.log(OptionIdea[0])
    return (
        <Document>
            <Page size="A4" style={styles.page} orientation={'landscape'} >
                <View style={styles.section}>
                    <Image style={styles.logo} src={Logo} />
                    <OptionIdeaTitle title={titleOptionIdea} />
                    {/*<OptionIdeaNo {...OptionIdea} />
            <BillTo {...OptionIdea} />*/}
                    {OptionIdea[0] !== undefined ? <OptionIdeaItemsTable OptionIdea = {OptionIdea} /> : <></>}
                    <OptionIdeaThankYouMsg />
                </View>
            </Page>
        </Document>
    )
};

export default ReportPDF