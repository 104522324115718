import firebase from "firebase/app";
import { Redirect } from "react-router";

function handleLogout(){
    firebase.auth().signOut().then(() => {
        // Sign-out successful.
        console.log('sign out successfully')
        //window.localStorage.removeItem("auth")
        //window.localStorage.removeItem("token")
        localStorage.removeItem('email')
        return <Redirect to ="/auth/login"/>
        //window.location.reload();
      }).catch((error) => {
        // An error happened.
      });
}
export default handleLogout;