/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import "firebase/auth";
import 'firebase/firestore';
import "firebase/database";
// If widgets props are missing, use widgetPropsAny
export class TechnicalAnalysisWidget extends React.Component {

  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js'
    script.async = true;
    script.innerHTML = JSON.stringify({
      "interval": "1D",
      "width":localStorage.getItem('width'),
      "isTransparent": false,
      "height": 450,
      "symbol": "NASDAQ:AAPL",
      "showIntervalTabs": true,
      "locale": "en",
      "colorTheme": "dark"
    })
    document.getElementById("myContainerTechnicalAnalysisWidget").appendChild(script);
  }

  render() {
    return (
      <>
        <div id="myContainerTechnicalAnalysisWidget">
          <div className="tradingview-widget-container">
            <div className="tradingview-widget-container__widget">
            </div>
          </div>
        </div>
        <div class="tradingview-widget-copyright"><a href="https://www.tradingview.com/symbols/NASDAQ-AAPL/technicals/" rel="noreferrer" target="_blank"><span class="blue-text">Technical Analysis for AAPL</span></a> by TradingView</div>
      </>
    );
  }
}
export default TechnicalAnalysisWidget;
