/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Login from "views/authUI/Login.js";
import Register from "views/authUI/Register.js";
import AboutUs from "views/authUI/AboutUs.js"

export const authRoutes=[
    {
      path: "/AboutUs",
      name: "About Us",
      component: AboutUs,
      layout: "/auth",
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
      layout: "/auth",
    },
    {
      path: "/register",
      name: "Register",
      component: Register,
      layout: "/auth",
    },
  
];
export default authRoutes;
