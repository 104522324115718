/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import "firebase/auth";
import 'firebase/firestore';
import "firebase/database";
// If widgets props are missing, use widgetPropsAny
export class TickerTapeWidget extends React.Component {
  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js'
    script.async = true;
    script.innerHTML = JSON.stringify(
      {
        "symbols": [
          /*{
            "proName": "FOREXCOM:SPXUSD",
            "title": "S&P 500"
          },
          {
            "description": "Dow",
            "proName": "FOREXCOM:DJI"
          },
          {
            "proName": "FOREXCOM:NSXUSD",
            "title": "Nasdaq 100"
          },
          {
            "description": "Russle 2000",
            "proName": "FOREXCOM:US2000"
          },
          {
            "description": "Crude Oil",
            "proName": "TVC:USOIL"
          },
          {
            "description": "Gold ",
            "proName": "TVC:GOLD"
          },
          */
          {
            "proName": "FX_IDC:EURUSD",
            "title": "EUR/USD"
          },
          {
            "proName": "BITSTAMP:BTCUSD",
            "title": "BTC/USD"
          },
          {
            "proName": "BITSTAMP:ETHUSD",
            "title": "ETH/USD"
          },
          {
            "description": "GBP/USD",
            "proName": "OANDA:GBPUSD"
          },
          {
            "description": "Silver",
            "proName": "TVC:SILVER"
          },
          {
            "description": "Vix",
            "proName": "CBOE:VIX"
          },
          {
            "description": "10 Year Bond",
            "proName": "NASDAQ:IEF"
          },
          {
            "description": "Nikkei",
            "proName": "FOREXCOM:JPXJPY"
          },
          {
            "description": "Honkong",
            "proName": "FOREXCOM:HKXHKD"
          },
          {
            "description": "Shanghai",
            "proName": "FOREXCOM:CHN50"
          },
        ],
        "showSymbolLogo": true,
        "colorTheme": "dark",
        "isTransparent": false,
        "displayMode": "adaptive",
        "locale": "en"

      }
    )
    document.getElementById("myContainerTickerTapeWidget").appendChild(script);
  }
  render() {
    return (
      <>
        <div id="myContainerTickerTapeWidget">
          <div className="tradingview-widget-container">
            <div className="tradingview-widget-container__widget">
            </div>
          </div>
        </div>
        <div class="tradingview-widget-copyright"><a href="https://www.tradingview.com" rel="noreferrer" target="_blank"><span class="blue-text">Ticker Tape</span></a> by TradingView</div>
      </>
    );
  }
}
export default TickerTapeWidget;
