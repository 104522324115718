/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import "firebase/auth";
import 'firebase/firestore';
import "firebase/database";
// If widgets props are missing, use widgetPropsAny
export class CryptocurrencyMarketWidget extends React.Component {
  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-screener.js'
    script.async = true;
    script.innerHTML = JSON.stringify(
      {
        "width": localStorage.getItem('width'),
        "height": localStorage.getItem('height'),
        "defaultColumn": "overview",
        "screener_type": "crypto_mkt",
        "displayCurrency": "USD",
        "colorTheme": "dark",
        "locale": "en",
        
      }
    )
    document.getElementById("myContainerCryptocurrencyMarketWidget").appendChild(script);
  }
  render() {
    return (
      <>
        <div id="myContainerCryptocurrencyMarketWidget">
          <div className="tradingview-widget-container">
            <div className="tradingview-widget-container__widget">
            </div>
          </div>
        </div>
        <div class="tradingview-widget-copyright"><a href="https://www.tradingview.com/markets/cryptocurrencies/prices-all/" rel="noreferrer" target="_blank"><span class="blue-text">Cryptocurrency Markets</span></a> by TradingView</div>
      </>
    );
  }
}
export default CryptocurrencyMarketWidget;
