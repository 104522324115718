/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import "firebase/auth";
import 'firebase/firestore';
import "firebase/database";
// If widgets props are missing, use widgetPropsAny
export class MiniChartWidget extends React.Component {
  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js'
    script.async = true;
    script.innerHTML = JSON.stringify(
      {
        "symbol": "NASDAQ:AAPL",
        "width": localStorage.getItem('width'),
        "height": 170,
        "locale": "en",
        "dateRange": "12M",
        "colorTheme": "dark",
        "trendLineColor": "rgba(41, 98, 255, 1)",
        "underLineColor": "rgba(41, 98, 255, 0.3)",
        "underLineBottomColor": "rgba(41, 98, 255, 0)",
        "isTransparent": false,
        "autosize": false,
        "largeChartUrl": ""
      }
      )
    document.getElementById("myContainerMiniChartWidget").appendChild(script);
  }
  render() {
  return (
    <>
      <div id="myContainerMiniChartWidget">
        <div className="tradingview-widget-container">
          <div className="tradingview-widget-container__widget">
          </div>
        </div>
      </div>
      <div class="tradingview-widget-copyright"><a href="https://www.tradingview.com/symbols/EURUSD/?exchange=FX" rel="noreferrer" target="_blank"><span class="blue-text">EURUSD Rates</span></a> by TradingView</div>
    </>
  );
}
}
export default MiniChartWidget;
