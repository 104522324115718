/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect } from "react";
import { Card, Container } from 'reactstrap';
import CardsHeader from "components/Headers/CardsHeader.js";
import { UOADataFirebase } from "./CustomComponent/BackendDataConfig";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import MaterialTable from "material-table";
import { Typography } from '@material-ui/core';
import { adminUserList, tableIcons } from "./CustomComponent/reuseableFn";
import columnOptionData from "./CustomComponent/TableColumn";
import { OptionDataAdd, OptionDataUpdate } from "./CustomComponent/UpdateAddFirebaseData";
var username = localStorage.getItem("email") === null ? 'kingswayanish' : localStorage.getItem("email").split('@')[0].replace(/[&\\#,+()$~%.'":*?<>{}]/, '');

function UOA() {
  var selectedDate = localStorage.getItem("selectedDate") === null ? new Date() : new Date(localStorage.getItem("selectedDate"));
  const [UOAData, setUOAData] = React.useState([]);
  const [SelectedRowAdd, setSelectedRowAdd] = React.useState();
  const [SelectedRowUpdate, setSelectedRowUpdate] = React.useState();
  const [SelectedRowDelete, setSelectedRowDelete] = React.useState();
  //initialize to get data of all customers, order history
  let date = selectedDate.getDate();
  let month = selectedDate.getMonth() + 1;
  let year = selectedDate.getFullYear();
  useEffect(() => {
    let date1 = selectedDate.getDate();
    let month1 = selectedDate.getMonth() + 1;
    let year1 = selectedDate.getFullYear();
    const dbRef = firebase.database().ref(UOADataFirebase + "/" + year1 + "/" + month1 + "/" + date1);
    dbRef.get().then((snapshot) => {
      let users = [];
      if (snapshot.exists()) {
        snapshot.forEach((snap) => {
          users.push({ id: snap.key, ...snap.val() });
        });
        setUOAData(users);

      } else {
        console.log("No data available");
      }
      //console.log(users[0]);
    })
      .catch((error) => {
        console.error(error);
      });
  }, [SelectedRowAdd, SelectedRowUpdate, SelectedRowDelete]);


  if (UOAData[0] !== undefined) {
    if (SelectedRowUpdate !== undefined) {
      OptionDataUpdate(SelectedRowUpdate, UOADataFirebase)
      setSelectedRowUpdate(undefined)
    } else if (SelectedRowAdd !== undefined) {
      OptionDataAdd(SelectedRowAdd, UOADataFirebase)
      setSelectedRowAdd(undefined)
    } else if (SelectedRowDelete !== undefined) {
      firebase.database().ref(UOADataFirebase + "/" + year + "/" + month + "/" + date + "/" + SelectedRowDelete.id).set(null)
      setSelectedRowDelete(undefined)
    }
  }
  const MyNewTitle = ({ text = "Table Title", variant = "h6" }) => (
    <Typography
      variant={variant}
      style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
    >
      {text}
      "fdsfasdsas"
    </Typography>
  );

  return (
    <>
      <CardsHeader />
      <br /><br /><br />
      <Container className="mt--6" fluid>
        <Card>
          {adminUserList.includes(username) ?
            <>
              <MaterialTable
                icons={tableIcons}
                //title={<MyNewTitle variant="h5" text="Short Term Option Idea" />}
                title="Unusual Option Activity"
                columns={columnOptionData.map((c) => ({ ...c, tableData: undefined }))}
                data={UOAData}
                options={{
                  exportButton: true,
                  paging: true,
                  pageSize: 10,
                  pageSizeOptions: [1, 5, 10, 20, 50, 100],
                  rowStyle: rowData => {
                    if (rowData.Type === "Call") {
                      return { backgroundColor: "#c4ffd6" }
                    } else if (rowData.Type === "Put") {
                      return { backgroundColor: "#ffcfcf" }
                    } else {
                      return { backgroundColor: "#ffffff" }
                    }
                  },
                  headerStyle: {
                    backgroundColor: '#0099cc',
                    color: '#ffffff',
                    textColor: '#ffffff',
                  }
                }}
                localization={{
                  expor: < MyNewTitle variant="h5" text="Short Term Option Idea" />
                }}
                editable={{
                  onRowAdd: newData =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        setSelectedRowAdd(newData)
                        setUOAData([...UOAData, newData]);
                        resolve();
                      }, 1000)
                    }),
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        const dataUpdate = [...UOAData];
                        const index = oldData.tableData.id;
                        dataUpdate[index] = newData;
                        setUOAData([...dataUpdate]);
                        setSelectedRowUpdate(dataUpdate[index])
                        resolve();
                      }, 5000)
                    }),
                  onRowDelete: oldData =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        const dataDelete = [...UOAData];
                        const index = oldData.tableData.id;
                        const dataRemove = dataDelete.splice(index, 1);
                        setUOAData([...dataDelete]);
                        setSelectedRowDelete(dataRemove[0])
                        resolve()
                      }, 1000)
                    }),
                }}
              />
            </> : <>
              <MaterialTable
                icons={tableIcons}
                title="Unusual Option Activity"
                columns={columnOptionData}
                data={UOAData}

                options={{
                  exportButton: true,
                  paging: true,
                  pageSize: 10,
                  pageSizeOptions: [1, 5, 10, 20, 50, 100],
                  rowStyle: rowData => {
                    if (rowData.Type === "Call") {
                      return { backgroundColor: "#c4ffd6" }
                    } else if (rowData.Type === "Put") {
                      return { backgroundColor: "#ffcfcf" }
                    } else {
                      return { backgroundColor: "#ffffff" }
                    }
                  },
                  headerStyle: {
                    backgroundColor: '#0099cc',
                    color: '#ffffff',
                    textColor: '#ffffff',
                  }
                }}
              />
            </>
          }
        </Card>
      </Container>
    </>
  );
}

export default UOA;