import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import {
    FormatDateMMDDYYY,
    UndefinedReturnNull,
    UndefinedReturnTodayDate,
    UndefinedReturnZero
} from "./reuseableFn";

//var username = localStorage.getItem("email") === null ? 'kingswayanish' : localStorage.getItem("email").split('@')[0].replace(/[&\\#,+()$~%.'":*?<>{}]/, '');
var selectedDate = localStorage.getItem("selectedDate") === null ? new Date() : new Date(localStorage.getItem("selectedDate"));
let date = selectedDate.getDate();
let month = selectedDate.getMonth() + 1;
let year = selectedDate.getFullYear();

//option update data
export function OptionDataUpdate(RowData, DataLocation) {
    firebase.database().ref(DataLocation + "/" + year + "/" + month + "/" + date + "/" + RowData.id).update({
        Symbol: UndefinedReturnNull(RowData.Symbol),
        Last: UndefinedReturnZero(RowData.Last),
        Type: UndefinedReturnNull(RowData.Type),
        Strike: UndefinedReturnZero(RowData.Strike),
        ExpDate: FormatDateMMDDYYY(UndefinedReturnTodayDate(RowData.ExpDate)),
        OptionsVolume: UndefinedReturnZero(RowData.OptionsVolume),
        OpenInterest: UndefinedReturnZero(RowData.OpenInterest),
        OptionPrice: UndefinedReturnZero(RowData.OptionPrice),
        MsgDate: FormatDateMMDDYYY(UndefinedReturnTodayDate(RowData.MsgDate)),
        Message: UndefinedReturnNull(RowData.Message),
    })
}
export default OptionDataUpdate;

//option add new data
export function OptionDataAdd(RowData, DataLocation) {
    firebase.database().ref(DataLocation + "/" + year + "/" + month + "/" + date).push({
        Symbol: UndefinedReturnNull(RowData.Symbol),
        Last: UndefinedReturnZero(RowData.Last),
        Type: UndefinedReturnNull(RowData.Type),
        Strike: UndefinedReturnZero(RowData.Strike),
        ExpDate: FormatDateMMDDYYY(UndefinedReturnTodayDate(RowData.ExpDate)),
        OptionsVolume: UndefinedReturnZero(RowData.OptionsVolume),
        OpenInterest: UndefinedReturnZero(RowData.OpenInterest),
        OptionPrice: UndefinedReturnZero(RowData.OptionPrice),
        MsgDate: FormatDateMMDDYYY(UndefinedReturnTodayDate(RowData.MsgDate)),
        Message: UndefinedReturnNull(RowData.Message),
    })
}

//stock watchlist update data
export function StockWatchListUpdate(RowData, DataLocation) {
    firebase.database().ref(DataLocation + "/" + year + "/" + month + "/" + date + "/" + RowData.id).update({
        Symbol: UndefinedReturnNull(RowData.Symbol),
        Last: UndefinedReturnZero(RowData.Last),
        Date: FormatDateMMDDYYY(UndefinedReturnTodayDate(RowData.Date)),
        S1: UndefinedReturnZero(RowData.S1),
        S2: UndefinedReturnZero(RowData.S2),
        R1: UndefinedReturnZero(RowData.R1),
        R2: UndefinedReturnZero(RowData.R2),
        MsgDate: FormatDateMMDDYYY(UndefinedReturnTodayDate(RowData.MsgDate)),
        Message: UndefinedReturnNull(RowData.Message),
    })
}

//stock watchlist add new data
export function StockWatchListAdd(RowData, DataLocation) {
    firebase.database().ref(DataLocation + "/" + year + "/" + month + "/" + date).push({
        Symbol: UndefinedReturnNull(RowData.Symbol),
        Last: UndefinedReturnZero(RowData.Last),
        Date: FormatDateMMDDYYY(UndefinedReturnTodayDate(RowData.Date)),
        S1: UndefinedReturnZero(RowData.S1),
        S2: UndefinedReturnZero(RowData.S2),
        R1: UndefinedReturnZero(RowData.R1),
        R2: UndefinedReturnZero(RowData.R2),
        MsgDate: FormatDateMMDDYYY(UndefinedReturnTodayDate(RowData.MsgDate)),
        Message: UndefinedReturnNull(RowData.Message),
    })
}

//short interest stock  update data
export function StockShortInterestUpdate(RowData, DataLocation) {
    firebase.database().ref(DataLocation + "/" + year + "/" + month + "/" + date + "/" + RowData.id).update({
        Symbol: UndefinedReturnNull(RowData.Symbol),
        Last: UndefinedReturnZero(RowData.Last),
        Date: FormatDateMMDDYYY(UndefinedReturnTodayDate(RowData.Date)),
        ShortInterest: UndefinedReturnZero(RowData.ShortInterest),
        MsgDate: FormatDateMMDDYYY(UndefinedReturnTodayDate(RowData.MsgDate)),
        Message: UndefinedReturnNull(RowData.Message),
    })
}

//short interest stock add new data
export function StockShortInterestAdd(RowData, DataLocation) {
    firebase.database().ref(DataLocation + "/" + year + "/" + month + "/" + date).push({
        Symbol: UndefinedReturnNull(RowData.Symbol),
        Last: UndefinedReturnZero(RowData.Last),
        Date: FormatDateMMDDYYY(UndefinedReturnTodayDate(RowData.Date)),
        ShortInterest: UndefinedReturnZero(RowData.ShortInterest),
        MsgDate: FormatDateMMDDYYY(UndefinedReturnTodayDate(RowData.MsgDate)),
        Message: UndefinedReturnNull(RowData.Message),
    })
}

//PL -Option add
export function PLOptionAdd(RowData, DataLocation, username) {
    firebase.database().ref(DataLocation + '/' + username + "/" + year + "/" + month).push({
        Symbol: UndefinedReturnNull(RowData.Symbol),
        Strike: UndefinedReturnZero(RowData.Strike),
        NoOfContract: UndefinedReturnZero(RowData.NoOfContract),
        BuyPrice: UndefinedReturnZero(RowData.BuyPrice),
        SellPrice: UndefinedReturnZero(RowData.SellPrice),
        Profit: UndefinedReturnZero(RowData.Profit),
        Type: UndefinedReturnZero(RowData.Type),
        ExpDate: FormatDateMMDDYYY(UndefinedReturnTodayDate(RowData.ExpDate)),
        BuyDate: FormatDateMMDDYYY(UndefinedReturnTodayDate(RowData.BuyDate)),
        SellDate: FormatDateMMDDYYY(UndefinedReturnTodayDate(RowData.SellDate)),
    })
}
//PL -Option update
export function PLOptionUpdate(RowData, DataLocation, username) {
    firebase.database().ref(DataLocation + '/' + username + "/" + year + "/" + month).update({
        Symbol: UndefinedReturnNull(RowData.Symbol),
        Strike: UndefinedReturnZero(RowData.Strike),
        NoOfContract: UndefinedReturnZero(RowData.NoOfContract),
        BuyPrice: UndefinedReturnZero(RowData.BuyPrice),
        SellPrice: UndefinedReturnZero(RowData.SellPrice),
        Profit: UndefinedReturnZero(RowData.Profit),
        Type: UndefinedReturnZero(RowData.Type),
        ExpDate: FormatDateMMDDYYY(UndefinedReturnTodayDate(RowData.ExpDate)),
        BuyDate: FormatDateMMDDYYY(UndefinedReturnTodayDate(RowData.BuyDate)),
        SellDate: FormatDateMMDDYYY(UndefinedReturnTodayDate(RowData.SellDate)),
    })
}


