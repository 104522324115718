/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect } from "react";
import { ShortTermIdeaData } from "../CustomComponent/BackendDataConfig";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import MaterialTable from "material-table";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import IconButton from '@mui/material/IconButton';
import FormLabel from '@mui/material/FormLabel';
import { Typography } from '@material-ui/core';
import {  PDFDownloadLink } from '@react-pdf/renderer';
import { adminUserList, tableIcons } from "../CustomComponent/reuseableFn";
import columnOptionData from "../CustomComponent/TableColumn";
import { OptionDataAdd, OptionDataUpdate } from "../CustomComponent/UpdateAddFirebaseData";
import ReportPDF from "components/PDF/OptionIdea/OptionIdeaPDF";
var username = localStorage.getItem("email") === null ? 'kingswayanish' : localStorage.getItem("email").split('@')[0].replace(/[&\\#,+()$~%.'":*?<>{}]/, '');

function ShortTermIdea() {
  const [shortTermOptionData, setShortTermOptionData] = React.useState([]);
  const [SelectedRowAdd, setSelectedRowAdd] = React.useState();
  const [SelectedRowUpdate, setSelectedRowUpdate] = React.useState();
  const [SelectedRowDelete, setSelectedRowDelete] = React.useState();
  //initialize to get data of all customers, order history
  var selectedDate = localStorage.getItem("selectedDate") === null ? new Date() : new Date(localStorage.getItem("selectedDate"));
  let date = selectedDate.getDate();
  let month = selectedDate.getMonth() + 1;
  let year = selectedDate.getFullYear();
  useEffect(() => {
    let date1 = selectedDate.getDate();
    let month1 = selectedDate.getMonth() + 1;
    let year1 = selectedDate.getFullYear();
    const dbRef = firebase.database().ref(ShortTermIdeaData + "/" + year1 + "/" + month1 + "/" + date1);
    dbRef.get().then((snapshot) => {
      let users = [];
      if (snapshot.exists()) {
        snapshot.forEach((snap) => {
          users.push({ id: snap.key, ...snap.val() });
        });
        setShortTermOptionData(users);

      } else {
        console.log("No data available");
      }
      //console.log(users[0]);
    })
      .catch((error) => {
        console.error(error);
      });
  }, [SelectedRowAdd, SelectedRowUpdate, SelectedRowDelete]);


  if (shortTermOptionData[0] !== undefined) {
    if (SelectedRowUpdate !== undefined) {
      OptionDataUpdate(SelectedRowUpdate, ShortTermIdeaData)
      setSelectedRowUpdate(undefined)
    } else if (SelectedRowAdd !== undefined) {
      OptionDataAdd(SelectedRowAdd, ShortTermIdeaData)
      setSelectedRowAdd(undefined)
    } else if (SelectedRowDelete !== undefined) {
      firebase.database().ref(ShortTermIdeaData + "/" + year + "/" + month + "/" + date + "/" + SelectedRowDelete.id).set(null)
      setSelectedRowDelete(undefined)
    }
  }
  const MyNewTitle = ({ text }) => {
    return (
      <>
        <FormLabel
          filled={true}
          style={{ paddingRight: "15px", fontSize: "18px" }} >{text}</FormLabel>
        <PDFDownloadLink document={<ReportPDF OptionIdea={shortTermOptionData}  titleOptionIdea = {"Short Term Option Idea"}/>} fileName="Short_Term_Option_Idea.pdf">
          <Button style={{
            borderRadius: 10,
            backgroundColor: "#0099cc",
            color: "#ffffff",
            padding: "5px 10px",
            fontSize: "14px"
          }}
            startIcon={<CloudDownloadIcon />}>Download</Button>
        </PDFDownloadLink>
      </>
    )
  };
  return (
    <>
      {adminUserList.includes(username) ?
        <>
          <MaterialTable
            icons={tableIcons}
            title={<MyNewTitle text="Short Term Option Idea" />}
            columns={columnOptionData.map((c) => ({ ...c, tableData: undefined }))}
            data={shortTermOptionData}
            options={{
              //exportButton: true,
              paging: true,
              draggable: true,
              pageSize: 10,
              pageSizeOptions: [1, 5, 10, 20, 50, 100],
              rowStyle: rowData => {
                if (rowData.Type === "Call") {
                  return { backgroundColor: "#c4ffd6" }
                } else if (rowData.Type === "Put") {
                  return { backgroundColor: "#ffcfcf" }
                } else {
                  return { backgroundColor: "#ffffff" }
                }
              },
              headerStyle: {
                backgroundColor: '#0099cc',
                color: '#ffffff',
                textColor: '#ffffff',
              }
            }}
            localization={{
              expor: < MyNewTitle variant="h5" text="Short Term Option Idea" />
            }}
            editable={{
              onRowAdd: newData =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    setSelectedRowAdd(newData)
                    setShortTermOptionData([...shortTermOptionData, newData]);
                    resolve();
                  }, 1000)
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    const dataUpdate = [...shortTermOptionData];
                    const index = oldData.tableData.id;
                    dataUpdate[index] = newData;
                    setShortTermOptionData([...dataUpdate]);
                    setSelectedRowUpdate(dataUpdate[index])
                    resolve();
                  }, 5000)
                }),
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    const dataDelete = [...shortTermOptionData];
                    const index = oldData.tableData.id;
                    const dataRemove = dataDelete.splice(index, 1);
                    setShortTermOptionData([...dataDelete]);
                    setSelectedRowDelete(dataRemove[0])
                    resolve()
                  }, 1000)
                }),
            }}
          />
        </> : <>
          <MaterialTable
            icons={tableIcons}
            title="Short Term Option Idea"
            columns={columnOptionData}
            data={shortTermOptionData}

            options={{
              //exportButton: true,
              paging: true,
              pageSize: 10,
              pageSizeOptions: [1, 5, 10, 20, 50, 100],
              rowStyle: rowData => {
                if (rowData.Type === "Call") {
                  return { backgroundColor: "#c4ffd6" }
                } else if (rowData.Type === "Put") {
                  return { backgroundColor: "#ffcfcf" }
                } else {
                  return { backgroundColor: "#ffffff" }
                }
              },
              headerStyle: {
                backgroundColor: '#0099cc',
                color: '#ffffff',
                textColor: '#ffffff',
              }
            }}
          />
        </>
      }
    </>
  );
}

export default ShortTermIdea;