/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect, useState } from "react";
import { StockPLConfig } from "../CustomComponent/BackendDataConfig";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import MaterialTable from "material-table";
import { tableIcons } from "../CustomComponent/reuseableFn";
var username = localStorage.getItem("email") === null ? 'kingswayanish' : localStorage.getItem("email").split('@')[0].replace(/[&\\#,+()$~%.'":*?<>{}]/, '');

function StockPL() {
  const [StockPLData, setStockPLData] = React.useState([]);
 
  //initialize to get data of all customers, order history
  useEffect(() => {

    const dbRef = firebase.database().ref(StockPLConfig + '/' + username);
    dbRef.get().then((snapshot) => {
      let users = [];
      if (snapshot.exists()) {
        snapshot.forEach((snap) => {
          users.push(snap.val());
        });
        setStockPLData(users[0]);

      } else {
        console.log("No data available");
      }
     // console.log(users[0]);
    })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  const [columnOptionData] = useState([
    { title: "Symbol", field: "Symbol" },
    { title: "Strike", field: "Strike", type: 'currency' },
    { title: "Buy Price", field: "BuyPrice", type: 'currency' },
    { title: "Sell Price", field: "SellPrice", type: 'currency' },
    {
      title: "Profit", field: "Profit", type: 'currency',
      render: rowData => {
        var sellprice = rowData.SellPrice === undefined ? 0 : rowData.SellPrice
        var calculation = (sellprice - rowData.BuyPrice) * 100 * rowData.NoOfContract
        return Math.round(calculation).toFixed(2)
      },
    },
  ]);

  if (StockPLData[0] !== undefined) {
    // console.log(StockPLData)
    StockPLData.map(a => {
      if (a['Symbol'] !== undefined) {
        a['Symbol'] = a['Symbol'].toUpperCase();
      }
      return null;
    })
    firebase.database().ref(StockPLConfig + '/' + username).set({
      StockPLData: StockPLData
    })
  }
  return (
    <>
      <MaterialTable
        icons={tableIcons}
        title="Stock PL"
        columns={columnOptionData}
        data={StockPLData}

        options={{
          exportButton: true,
          paging: true,
          pageSize: 10,
          pageSizeOptions: [1, 5, 10, 20, 50, 100],
          rowStyle: rowData => {
            if ((rowData.SellPrice - rowData.BuyPrice) > 0) {
              return { backgroundColor: "#c4ffd6" }
            } else if ((rowData.SellPrice - rowData.BuyPrice) < 0) {
              return { backgroundColor: "#ff9c9c" }
            } else {
              return { backgroundColor: "#ffffff" }
            }
          },
          headerStyle: {
            backgroundColor: '#0099cc',
            color: '#ffffff',
            textColor: '#ffffff',
          }
        }}
        editable={{
          onRowAdd: newData =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                setStockPLData([...StockPLData, newData]);
                resolve();
              }, 1000)
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {

                const dataUpdate = [...StockPLData];
                const index = oldData.tableData.id;
                dataUpdate[index] = newData;
                setStockPLData([...dataUpdate]);
                resolve();
              }, 5000)
            }),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                const dataDelete = [...StockPLData];
                const index = oldData.tableData.id;
                dataDelete.splice(index, 1);
                setStockPLData([...dataDelete]);
                resolve()
              }, 1000)
            }),
        }}
      />
    </>
  );
}

export default StockPL;