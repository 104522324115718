import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({

    titleContainer: {
        //flexDirection: 'row',
        marginTop: 10,
    },
    reportTitle: {
        color: '#61dafb',
        //letterSpacing: 4,
        fontSize: 18,
        textAlign: 'center',
        textTransform: 'uppercase',
    }
});


const OptionIdeaTitle = (title) => {
   // console.log(title['title'])
    return (
        <View style={styles.titleContainer}>
            <Text style={styles.reportTitle}>{title['title']}</Text>
        </View>
    )
};

export default OptionIdeaTitle