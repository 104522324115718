/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
//import Dashboard from "views/adminUI/Dashboard.js";
//import Customers from "views/adminUI/Customers";

import OptionFlow from "views/adminUI/OptionFlow";
import UOA from "views/adminUI/UOA";
import ChangeInOI from "views/adminUI/moreOptionData/ChangeInOI";
import HighestOI from "views/adminUI/moreOptionData/HighestOI";
import OptionVolume from "views/adminUI/moreOptionData/OptionVolume";
import Charts from "views/adminUI/Charts";
//import MarketCalender from "views/adminUI/MarketCalender";
import Education from "views/adminUI/Education";
import Profile from "views/adminUI/Profile";
import Aggrement from "views/adminUI/Aggrement";
import "./customStyle.css"
import News from "views/adminUI/News";
import TermIdea from "views/adminUI/TermIdea"
import Support from "views/adminUI/Support";
import Settings from "views/adminUI/Settings";
import ForexMarketData from "views/adminUI/Forex/ForexMarketData";
import ForexCrossRate from "views/adminUI/Forex/ForexCrossRate";
import ForexHeatMap from "views/adminUI/Forex/ForexHeatMap";
import StockMarketData from "views/adminUI/Stock/StockMarketData";
import CompanyAnalysis from "views/adminUI/Stock/CompanyAnalysis";
import SelectForexView from "views/adminUI/Forex/SelectForexView";
import CryptoMarketData from "views/adminUI/CryptoCurrency/CryptoMarketData"
import MajorCryptoMarketValuation from "views/adminUI/CryptoCurrency/MajorCryptoMarketValuation"
import Watchlist from "views/adminUI/Watchlist";
import PLUserData from "views/adminUI/PLUserData";
import DiscordChat from "views/adminUI/DiscordChat";
import AboveAskOption from "views/adminUI/moreOptionData/AboveAskOption";

export const routes = [
  {
    path: "/optionflow",
    name: "Option Order Flow",
    miniName: "D",
    icon: "fas fa-stream",
    component: OptionFlow,
    layout: "/admin",
  },
  {
    path: "/charts",
    name: "Charts",
    miniName: "A",
    icon: "fas fa-chart-line",
    component: Charts,
    layout: "/admin",
  },
  {
    path: "/discordchat",
    name: "Discord",
    miniName: "A",
    icon: "fas fa-comments",
    component: DiscordChat,
    layout: "/admin",
   
  },
  {
    path: "/uoa",
    name: "Option UOA",
    miniName: "D",
    icon: "far fa-paper-plane",
    component: UOA,
    layout: "/admin",
  },
  {
    path: "/tradeidea",
    name: "Option Trade Idea",
    icon: "far fa-lightbulb",
    component: TermIdea,
    layout: "/admin",
  },
  {
    path: "/watchlist",
    name: "Watchlist",
    icon: "fas fa-glasses",
    component: Watchlist,
    layout: "/admin",
  },
  {
    path: "/pluserdata",
    name: "Profit-Loss",
    icon: "fas fa-hand-holding-usd",
    component: PLUserData,
    layout: "/admin",
  },
  /*{
    collapse: true,
    name: "Watchlist",
    icon: "fas fa-glasses",
    state: "mapsCollapse",
    views: [
      {
        path: "/dailystocklist",
        name: "Daily Stock List",
        miniName: "B",
        component: DailyStockList,
        layout: "/admin",
      },
      {
        path: "/shortintereststock",
        name: "Short Interest Stock",
        miniName: "C",
        component: ShortInterestStock,
        layout: "/admin",
      },
    ],
  },*/
  {
    collapse: true,
    name: "More Option Data",
    icon: "fa fa-info",
    state: "componentsCollapse",
    views: [
      /*{
        path: "/changeoi",
        name: "Change Open Interest",
        miniName: "B",
        component: ChangeInOI,
        layout: "/admin",
      },
      {
        path: "/highestoi",
        name: "Highest Open Interest",
        miniName: "C",
        component: HighestOI,
        layout: "/admin",
      },*/
      {
        path: "/optionvolume",
        name: "Option Volume",
        miniName: "G",
        component: OptionVolume,
        layout: "/admin",
      },
      {
        path: "/aboveaskoption",
        name: "Above-Ask Analysis",
        miniName: "G",
        component: AboveAskOption,
        layout: "/admin",
      }
    ],
  },
  {
    collapse: true,
    name: "Stock",
    icon: "fas fa-funnel-dollar",
    state: "examplesCollapse",
    views: [
      {
        path: "/stockmarketdata",
        name: "Real Time Stock Data",
        miniName: "P",
        component: StockMarketData,
        layout: "/admin",
      },
      {
        path: "/companyanalysis",
        name: "Company Analysis",
        miniName: "P",
        component: CompanyAnalysis,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Forex",
    icon: "fas fa-dollar-sign",
    state: "formsCollapse",
    views: [
      {
        path: "/forexmarketdata",
        name: "Real Time Forex Data",
        miniName: "P",
        component: ForexMarketData,
        layout: "/admin",
      },
      {
        path: "/forexcrossrate",
        name: "Forex Cross Rates",
        miniName: "B",
        component: ForexCrossRate,
        layout: "/admin",
      },
      {
        path: "/forexheatmap",
        name: "Forex Heat Map",
        miniName: "C",
        component: ForexHeatMap,
        layout: "/admin",
      },
      {
        path: "/selectforexview",
        name: "Forex Setting",
        miniName: "C",
        component: SelectForexView,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Cryptocurrency",
    icon: "fab fa-bitcoin",
    state: "dashboardsCollapse",
    views: [
      {
        path: "/cryptomarketdata",
        name: "Real Time Crypto Data",
        miniName: "B",
        component: CryptoMarketData,
        layout: "/admin",
      },
      {
        path: "/majorcryptomarketvaluation",
        name: "Major Crypto Valuation",
        miniName: "C",
        component: MajorCryptoMarketValuation,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/news",
    name: "News",
    miniName: "A",
    icon: "fas fa-newspaper",
    component: News,
    layout: "/admin",
  },
  /*{
    path: "/marketcalender",
    name: "Market Calender",
    miniName: "A",
    icon: "fa fa-calendar text-primary",
    component: MarketCalender,
    layout: "/admin",
  },*/
  {
    path: "/educatoin",
    name: "Education",
    miniName: "A",
    icon: "fa fa-book text-primary",
    component: Education,
    layout: "/admin",
  },
];

// this route is for profile, aggrement
export const otherRoutes = [
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    layout: "/admin",
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    layout: "/admin",
  },
  {
    path: "/aggrement",
    name: "Aggrement",
    component: Aggrement,
    layout: "/admin",
  },
  {
    path: "/support",
    name: "Support",
    component: Support,
    layout: "/admin",
  },
]
export default routes;
