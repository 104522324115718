/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect } from "react";
import { Card, Container } from 'reactstrap';
import { HighMomentumStockWatchList } from "../CustomComponent/BackendDataConfig";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import MaterialTable from "material-table";
import { adminUserList, tableIcons } from "../CustomComponent/reuseableFn";
import { columnStockWatchList } from "../CustomComponent/TableColumn";
import { StockWatchListAdd, StockWatchListUpdate } from "../CustomComponent/UpdateAddFirebaseData";
var username = localStorage.getItem("email") === null ? 'kingswayanish' : localStorage.getItem("email").split('@')[0].replace(/[&\\#,+()$~%.'":*?<>{}]/, '');

function HighMomentumStockList() {
  const [WeeklyWatchListData, setWeeklyWatchListData] = React.useState([]);
  const [SelectedRowAdd, setSelectedRowAdd] = React.useState();
  const [SelectedRowUpdate, setSelectedRowUpdate] = React.useState();
  const [SelectedRowDelete, setSelectedRowDelete] = React.useState();
  //initialize to get data of all customers, order history
  var selectedDate = localStorage.getItem("selectedDate") === null ? new Date() : new Date(localStorage.getItem("selectedDate"));
  let date = selectedDate.getDate();
  let month = selectedDate.getMonth() + 1;
  let year = selectedDate.getFullYear();
  useEffect(() => {
    let date1 = selectedDate.getDate();
    let month1 = selectedDate.getMonth() + 1;
    let year1 = selectedDate.getFullYear();
    const dbRef = firebase.database().ref(HighMomentumStockWatchList + "/" + year1 + "/" + month1 + "/" + date1);
    dbRef.get().then((snapshot) => {
      let users = [];
      if (snapshot.exists()) {
        snapshot.forEach((snap) => {
          users.push({ id: snap.key, ...snap.val() });
        });
        setWeeklyWatchListData(users);
        //console.log(users[0])
      } else {
        console.log("No data available");
      }
      //console.log(users[0]);
    })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  if (WeeklyWatchListData[0] !== undefined) {

    // console.log(WeeklyWatchListData)
    if (SelectedRowUpdate !== undefined) {
      StockWatchListUpdate(SelectedRowUpdate, HighMomentumStockWatchList)
      setSelectedRowUpdate(undefined)
    } else if (SelectedRowAdd !== undefined) {
      StockWatchListAdd(SelectedRowAdd, HighMomentumStockWatchList)
      setSelectedRowAdd(undefined)
    } else if (SelectedRowDelete !== undefined) {
      firebase.database().ref(HighMomentumStockWatchList + "/" + year + "/" + month + "/" + date + "/" + SelectedRowDelete.id).set(null)
      setSelectedRowDelete(undefined)
    }
  }

  return (
    <>
      <br /><br /><br />
      <Container className="mt--6" fluid>
        <Card>
          {adminUserList.includes(username) ?
            <>
              <MaterialTable
                icons={tableIcons}
                title="High Momentum Stock Watchlist"
                columns={columnStockWatchList}
                data={WeeklyWatchListData}
                options={{
                  exportButton: true,
                  paging: true,
                  pageSize: 10,
                  pageSizeOptions: [1, 5, 10, 20, 50, 100],
                  rowStyle: rowData => {
                    if (rowData.Type === "Call") {
                      return { backgroundColor: "#c4ffd6" }
                    } else if (rowData.Type === "Put") {
                      return { backgroundColor: "#ffcfcf" }
                    } else {
                      return { backgroundColor: "#ffffff" }
                    }
                  },
                  headerStyle: {
                    backgroundColor: '#0099cc',
                    color: '#ffffff',
                    textColor: '#ffffff',
                  }
                }}
                editable={{
                  onRowAdd: newData =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        setSelectedRowAdd(newData)
                        setWeeklyWatchListData([...WeeklyWatchListData, newData]);
                        resolve();
                      }, 1000)
                    }),
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        const dataUpdate = [...WeeklyWatchListData];
                        const index = oldData.tableData.id;
                        dataUpdate[index] = newData;
                        setWeeklyWatchListData([...dataUpdate]);
                        setSelectedRowUpdate(dataUpdate[index])
                        resolve();
                      }, 5000)
                    }),
                  onRowDelete: oldData =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        const dataDelete = [...WeeklyWatchListData];
                        const index = oldData.tableData.id;
                        const dataRemove = dataDelete.splice(index, 1);
                        setWeeklyWatchListData([...dataDelete]);
                        setSelectedRowDelete(dataRemove[0])
                        resolve()
                      }, 1000)
                    }),
                }}
              />
            </>
            :
            <>
              <MaterialTable
                icons={tableIcons}
                title="Stock Watchlist"
                columns={columnStockWatchList}
                data={WeeklyWatchListData}
                options={{
                  exportButton: true,
                  paging: true,
                  pageSize: 10,
                  pageSizeOptions: [1, 5, 10, 20, 50, 100],
                  rowStyle: rowData => {
                    if (rowData.Type === "Call") {
                      return { backgroundColor: "#c4ffd6" }
                    } else if (rowData.Type === "Put") {
                      return { backgroundColor: "#ffcfcf" }
                    } else {
                      return { backgroundColor: "#ffffff" }
                    }
                  },
                  headerStyle: {
                    backgroundColor: '#0099cc',
                    color: '#ffffff',
                    textColor: '#ffffff',
                  }
                }}
              />
            </>
          }
        </Card>
      </Container>
    </>
  );
}

export default HighMomentumStockList;
