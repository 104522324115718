export const columnOptionData = [
    { title: "Symbol", field: "Symbol" },
    { title: "Last", field: "Last", type: 'currency' },
    { title: "Type", field: "Type", lookup: { "Call": "Call", "Put": "Put" }, },
    { title: "Strike", field: "Strike", type: 'currency' },
    { title: "Exp Date", field: "ExpDate", type: 'date', },
    { title: "Option Volume", field: "OptionsVolume", type: 'numeric' },
    { title: "Open Interest", field: "OpenInterest", type: 'numeric' },
    { title: "Option Price", field: "OptionPrice", type: 'currency' },
    { title: "Msg Date", field: "MsgDate", type: 'date' },
    {
        title: "Message", field: "Message",
        cellStyle: {
            width: '50%'
        },
    },
];
export default columnOptionData;

export const columnStockWatchList = [
    { title: "Symbol", field: "Symbol" },
    { title: "Last", field: "Last", type: 'currency' },
    { title: "Date", field: "Date", type: 'date', },
    { title: "S1", field: "S1", type: 'currency', },
    { title: "S2", field: "S2", type: 'currency', },
    { title: "R1", field: "R1", type: 'currency', },
    { title: "R2", field: "R2", type: 'currency', },
    { title: "Msg Date", field: "MsgDate", type: 'date' },
    { title: "Message", field: "Message" },
];

export const columnShortInterestStock = [
    { title: "Symbol", field: "Symbol" },
    { title: "Last", field: "Last", type: 'currency' },
    { title: "Short Interest (%)", field: "ShortInterest", type: 'numeric', },
    { title: "Date", field: "Date", type: 'date', },
    { title: "Msg Date", field: "MsgDate", type: 'date' },
    { title: "Message", field: "Message" },
];