/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect } from "react";
import { LongTermIdeaData } from "../CustomComponent/BackendDataConfig";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import MaterialTable from "material-table";
import { adminUserList, tableIcons } from "../CustomComponent/reuseableFn";
import columnOptionData from "../CustomComponent/TableColumn";
import OptionDataUpdate, { OptionDataAdd } from "../CustomComponent/UpdateAddFirebaseData";
var username = localStorage.getItem("email") === null ? 'kingswayanish' : localStorage.getItem("email").split('@')[0].replace(/[&\\#,+()$~%.'":*?<>{}]/, '');

function LongTermIdea() {
  var selectedDate = localStorage.getItem("selectedDate") === null ? new Date() : new Date(localStorage.getItem("selectedDate"));
  const [shortTermOptionData, setShortTermOptionData] = React.useState([]);
  const [SelectedRowAdd, setSelectedRowAdd] = React.useState();
  const [SelectedRowUpdate, setSelectedRowUpdate] = React.useState();
  const [SelectedRowDelete, setSelectedRowDelete] = React.useState();
  //initialize to get data of all customers, order history
  let date = selectedDate.getDate();
  let month = selectedDate.getMonth() + 1;
  let year = selectedDate.getFullYear();
  useEffect(() => {
    let date1 = selectedDate.getDate();
    let month1 = selectedDate.getMonth() + 1;
    let year1 = selectedDate.getFullYear();
    const dbRef = firebase.database().ref(LongTermIdeaData + "/" + year1 + "/" + month1 + "/" + date1);
    dbRef.get().then((snapshot) => {
      let users = [];
      if (snapshot.exists()) {
        snapshot.forEach((snap) => {
          users.push({ id: snap.key, ...snap.val() });
        });
        setShortTermOptionData(users);

      } else {
        console.log("No data available");
      }
      //console.log(users[0]);
    })
      .catch((error) => {
        console.error(error);
      });
  }, [SelectedRowAdd, SelectedRowUpdate, SelectedRowDelete]);

  if (shortTermOptionData[0] !== undefined) {
    if (SelectedRowUpdate !== undefined) {
      OptionDataUpdate(SelectedRowUpdate, LongTermIdeaData)
      setSelectedRowUpdate(undefined)
    } else if (SelectedRowAdd !== undefined) {
      OptionDataAdd(SelectedRowAdd, LongTermIdeaData)
      setSelectedRowAdd(undefined)
    } else if (SelectedRowDelete !== undefined) {
      firebase.database().ref(LongTermIdeaData + "/" + year + "/" + month + "/" + date + "/" + SelectedRowDelete.id).set(null)
      setSelectedRowDelete(undefined)
    }
  }


  return (
    <>
      {adminUserList.includes(username) ?
        <>
          <MaterialTable
            icons={tableIcons}
            title="Long Term Option Idea"
            columns={columnOptionData.map((c) => ({ ...c, tableData: undefined }))}
            data={shortTermOptionData}
            options={{
              exportButton: true,
              paging: true,
              pageSize: 10,
              pageSizeOptions: [1, 5, 10, 20, 50, 100],
              rowStyle: rowData => {
                if (rowData.Type === "Call") {
                  return { backgroundColor: "#c4ffd6" }
                } else if (rowData.Type === "Put") {
                  return { backgroundColor: "#ffcfcf" }
                } else {
                  return { backgroundColor: "#ffffff" }
                }
              },
              headerStyle: {
                backgroundColor: '#0099cc',
                color: '#ffffff',
                textColor: '#ffffff',
              }
            }}
            editable={{
              onRowAdd: newData =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    setSelectedRowAdd(newData)
                    setShortTermOptionData([...shortTermOptionData, newData]);
                    resolve();
                  }, 1000)
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    const dataUpdate = [...shortTermOptionData];
                    const index = oldData.tableData.id;
                    dataUpdate[index] = newData;
                    setShortTermOptionData([...dataUpdate]);
                    setSelectedRowUpdate(dataUpdate[index])
                    resolve();
                  }, 5000)
                }),
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    const dataDelete = [...shortTermOptionData];
                    const index = oldData.tableData.id;
                    const dataRemove = dataDelete.splice(index, 1);
                    setShortTermOptionData([...dataDelete]);
                    setSelectedRowDelete(dataRemove[0])
                    resolve()
                  }, 1000)
                }),
            }}
          />
        </> : <>
          <MaterialTable
            icons={tableIcons}
            title="Long Term Option Idea"
            columns={columnOptionData}
            data={shortTermOptionData}

            options={{
              exportButton: true,
              paging: true,
              pageSize: 10,
              pageSizeOptions: [1, 5, 10, 20, 50, 100],
              rowStyle: rowData => {
                if (rowData.Type === "Call") {
                  return { backgroundColor: "#c4ffd6" }
                } else if (rowData.Type === "Put") {
                  return { backgroundColor: "#ffcfcf" }
                } else {
                  return { backgroundColor: "#ffffff" }
                }
              },
              headerStyle: {
                backgroundColor: '#0099cc',
                color: '#ffffff',
                textColor: '#ffffff',
              }
            }}
          />
        </>
      }
    </>
  );
}

export default LongTermIdea;