export const firebaseConfig = {
  apiKey: "AIzaSyBlCkdGhg314YygSFUMUz0rIMoKQcOy9aQ",
  authDomain: "yadada-trading.firebaseapp.com",
  projectId: "yadada-trading",
  storageBucket: "yadada-trading.appspot.com",
  messagingSenderId: "327345935422",
  appId: "1:327345935422:web:ea456a8a7c27ca41c16d6f",
  measurementId: "G-W5KHCRR7C1",
  databaseURL: "https://yadada-trading-default-rtdb.firebaseio.com/",
};
export default firebaseConfig;
