
function test() {

}
export default test();
//Note change last four character for 
// 1. testing of the application by "Test" 
// 2. production of the application by "Prod"
export const fireStoreWholeSaleCustomer = 'WholeSaleCustomerInfo';
export const databaseOrder = 'WholeSaleOrderTest';

//new configuration for yaadada trading
export const orderFlowData = 'test/test2';
export const UOADataFirebase = 'UOA';
export const ChangeOIData = 'test/test2';
export const HighestOIData = 'test/test2';
export const OptionVolumeData = 'test/test2';
export const ShortTermIdeaData = 'ShortTermIdea';
export const LongTermIdeaData = 'LongTermIdea';
export const HighMomentumStockWatchList = 'HighMomentumStockWatchList';
export const WeeklyStockWatchList = 'WeeklyStockWatchList';
export const ShortInterestStockData = 'ShortInterestStockWatchlist';
export const OptionPLConfig = 'OptionPL';
export const StockPLConfig = 'StockPL';
export const ProfileData = 'profile';