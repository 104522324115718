/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import {
  Col, Row, Card, Button, Form, Alert,
  Container, FormGroup, Label, Input,
  CardBody, CardTitle
} from 'reactstrap';
import CardsHeader from "components/Headers/CardsHeader.js";
import { ProfileData } from "./CustomComponent/BackendDataConfig"
import firebase from "firebase/app";
import "firebase/auth";
import 'firebase/firestore';
import "firebase/database";


export class Profile extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      firstName: null,
      lastName: null,
      email: localStorage.getItem('email'),
      phoneNumber: null,
      address: null,
      address2: null,
      city: null,
      userState: null,
      zip: null,
      emailToResetPassword: localStorage.getItem('email'),
      // this will get the string before @ and will remove special characters from string
      getEmailLocalStorageForID: localStorage.getItem('email').split('@')[0].replace(/[&\\#,+()$~%.'":*?<>{}]/,''),
      editProfileCondition: false,
      resetPasswordMessage: null,
      saveProfileMessage: null,
      saveProfileMessageCondition:false,
      onClickForgetPasswordButton: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleEditProfile = this.handleEditProfile.bind(this);
    this.handleSaveProfile = this.handleSaveProfile.bind(this);
  }
  componentDidMount() {
    const dbRef = firebase.database().ref(ProfileData);
    dbRef.get().then((snapshot) => {
      let users = []
      if (snapshot.exists()) {
        snapshot.forEach(snap => {
          users.push(snap.val())
        })
        users.map(a => {
          if (a.email === localStorage.getItem('email')) {
            //console.log('email match')
            this.setState({
              firstName: a.firstName !== undefined ? a.firstName : null,
              lastName: a.lastName !== undefined ? a.lastName : null,
              email: a.email !== undefined ? a.email : null,
              phoneNumber: a.phoneNumber !== undefined ? a.phoneNumber : null,
              address: a.address !== undefined ? a.address : null,
              address2: a.address2 !== undefined ? a.address2 : null,
              city: a.city !== undefined ? a.city : null,
              userState: a.userState !== undefined ? a.userState : null,
              zip: a.zip !== undefined ? a.zip : null,
            })
          }
          return null;
        })
      } else {
        console.log("No data available");
      }
    }).catch((error) => {
     // console.error(error);
    });
  }
  handleChange = event => {
    //console.log(event.target.id);
    // console.log(event.target.value);
    this.setState({ [event.target.id]: event.target.value });
  };

  handleSaveProfile = event => {
    event.preventDefault();
    this.setState({
      editProfileCondition: false,
     // saveProfileMessage:"Profile Save Successfully!"
    })
    firebase.database().ref(ProfileData + '/' + this.state.getEmailLocalStorageForID).set({
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      phoneNumber: this.state.phoneNumber,
      address: this.state.address,
      address2: this.state.address2,
      city: this.state.city,
      userState: this.state.userState,
      zip: this.state.zip,
    });
  }

  handleEditProfile = event => {
    event.preventDefault();
    this.setState({
      editProfileCondition: true
    })
  }

  handleForgetPassword = event => {
    event.preventDefault();
    firebase.auth().sendPasswordResetEmail(this.state.emailToResetPassword)
      .then((a) => {
        //this.props.history.push("/auth/login");
        this.setState({
          resetPasswordMessage: "Password reset email sent!"
        })
      })
      .catch((error) => {
        this.setState({
          resetPasswordMessage: error.message
        })
      });
  }

  handleForgetPasswordButton = () => {
    this.setState({
      onClickForgetPasswordButton: true
    })
  }
  handleForgetPasswordBackToProfile = () => {
    this.setState({
      onClickForgetPasswordButton: false
    })
  }
  render() {
    return (
      <>
      
        <CardsHeader />
        <br /><br /><br /><br />
        <Container className="mt--6" fluid>
          <Card>
            <CardBody className="py-5">
              {this.state.onClickForgetPasswordButton === false ?
                <>
                  <CardTitle className="mb-3" tag="h1">
                    Profile
                  </CardTitle>
                  <Form role="form" onSubmit={this.handleSaveProfile}>
                    <Row form>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="exampleEmail">First Name</Label>
                          <Input type="text" name="firstName" id="firstName"
                            onChange={this.handleChange}
                            readOnly={this.state.editProfileCondition ? false : true}
                            value={this.state.firstName}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="examplePassword">Last Name</Label>
                          <Input type="text" name="lastName" id="lastName" placeholder="password placeholder"
                            onChange={this.handleChange}
                            readOnly={this.state.editProfileCondition ? false : true}
                            value={this.state.lastName}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row form>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="exampleEmail">Phone Number</Label>
                          <Input type="number" name="phoneNumber" id="phoneNumber" placeholder="with a placeholder"
                            onChange={this.handleChange}
                            readOnly={this.state.editProfileCondition ? false : true}
                            value={this.state.phoneNumber}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="examplePassword">E-mail</Label>
                          <Input type="text" name="email" id="email" placeholder="password placeholder"
                            value={this.state.email}
                            disabled />
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup>
                      <Label for="exampleAddress">Address</Label>
                      <Input type="text" name="address" id="address" placeholder="1234 Main St"
                        onChange={this.handleChange}
                        readOnly={this.state.editProfileCondition ? false : true}
                        value={this.state.address}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleAddress2">Address 2</Label>
                      <Input type="text" name="address2" id="address2" placeholder="Apartment, studio, or floor"
                        onChange={this.handleChange}
                        readOnly={this.state.editProfileCondition ? false : true}
                        value={this.state.address2}
                      />
                    </FormGroup>
                    <Row form>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="exampleCity">City</Label>
                          <Input type="text" name="city" id="city"
                            onChange={this.handleChange}
                            readOnly={this.state.editProfileCondition ? false : true}
                            value={this.state.city}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label for="exampleState">State</Label>
                          <Input type="text" name="userState" id="userState"
                            onChange={this.handleChange}
                            readOnly={this.state.editProfileCondition ? false : true}
                            value={this.state.userState}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                        <FormGroup>
                          <Label for="exampleZip">Zip</Label>
                          <Input type="text" name="zip" id="zip"
                            onChange={this.handleChange}
                            readOnly={this.state.editProfileCondition ? false : true}
                            value={this.state.zip}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    {this.state.saveProfileMessageCondition !== false ?
                      <div><br></br>
                        <Alert color="danger">
                          {this.state.saveProfileMessage}
                        </Alert>
                      </div> : null}
                    <Button>Save</Button>
                    <Button onClick={this.handleEditProfile}>Edit</Button>
                    <Button onClick={this.handleForgetPasswordButton}>Reset Password</Button>
                  </Form>
                </>
                :
                <>
                  <CardTitle className="mb-3" tag="h1">
                    Enter Email to Reset Password
                  </CardTitle>
                  <Form role="form" onSubmit={this.handleForgetPassword}>

                    <FormGroup>
                      <Label for="exampleAddress">Email</Label>
                      <Input type="email" name="emailToResetPassword" id="emailToResetPassword" placeholder="info@yadadatrading.com"
                        onChange={this.handleChange}
                        value={this.state.emailToResetPassword}
                      />
                    </FormGroup>
                    {/*this.state.resetPasswordMessage !== null ?
                      <div><br></br>
                        <Alert color="danger">
                          {this.state.resetPasswordMessage}
                        </Alert>
                    </div> : null*/}
                    <Button>Submit</Button>
                    <Button onClick={this.handleForgetPasswordBackToProfile}>Back</Button>
                  </Form>
                </>
              }
            </CardBody>
          </Card>
        </Container>
      </>
    );
  }
}

export default Profile;
