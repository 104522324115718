
import React, { useEffect, useState } from "react";
import { OptionPLConfig } from "../CustomComponent/BackendDataConfig";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import MaterialTable from "material-table";
import { tableIcons } from "../CustomComponent/reuseableFn";
import { PLOptionAdd, PLOptionUpdate } from "../CustomComponent/UpdateAddFirebaseData";
var username = localStorage.getItem("email") === null ? 'kingswayanish' : localStorage.getItem("email").split('@')[0].replace(/[&\\#,+()$~%.'":*?<>{}]/, '');

function OptionsPL() {

  const [OptionPLData, setOptionPLData] = React.useState([]);
  var selectedDate = localStorage.getItem("selectedDate") === null ? new Date() : new Date(localStorage.getItem("selectedDate"));
  const [SelectedRowAdd, setSelectedRowAdd] = React.useState();
  const [SelectedRowUpdate, setSelectedRowUpdate] = React.useState();
  const [SelectedRowDelete, setSelectedRowDelete] = React.useState();

  let month = selectedDate.getMonth() + 1;
  let year = selectedDate.getFullYear();
  //initialize to get data of all customers, order history
  useEffect(() => {
    let month1 = selectedDate.getMonth() + 1;
    let year1 = selectedDate.getFullYear();
    const dbRef = firebase.database().ref(OptionPLConfig + '/' + username + "/" + year1 + "/" + month1);
    dbRef.get().then((snapshot) => {
      let users = [];
      if (snapshot.exists()) {
        snapshot.forEach((snap) => {
          users.push({ id: snap.key, ...snap.val() });
        });
        setOptionPLData(users.sort((a, b) => new Date(b.SellDate) - new Date(a.SellDate)))

      } else {
        console.log("No data available");
      }
      //console.log(users[0]);
    })
      .catch((error) => {
        console.error(error);
      });
  }, [SelectedRowAdd, SelectedRowUpdate, SelectedRowDelete, selectedDate]);

  const [columnOptionData] = useState([
    { title: "Symbol", field: "Symbol" },
    { title: "Strike", field: "Strike", type: 'currency' },
    { title: "No Of Contract", field: "NoOfContract", type: 'numeric' },
    { title: "Buy Price", field: "BuyPrice", type: 'currency' },
    { title: "Sell Price", field: "SellPrice", type: 'currency' },
    {
      title: "Profit", field: "Profit", type: 'currency',
      render: rowData => {
        var sellprice = rowData.SellPrice === undefined ? 0 : rowData.SellPrice
        var calculation = (sellprice - rowData.BuyPrice) * 100 * rowData.NoOfContract
        return Math.round(calculation).toFixed(2)
      },
    },
    { title: "Type", field: "Type", lookup: { "Call": "Call", "Put": "Put" }, },
    { title: "Exp Date", field: "ExpDate", type: 'date', },
    { title: "Buy Date", field: "BuyDate", type: 'date', },
    { title: "Sell Date", field: "SellDate", type: 'date', },
  ]);

  if (OptionPLData[0] !== undefined) {
    if (SelectedRowUpdate !== undefined) {
      PLOptionUpdate(SelectedRowUpdate, OptionPLConfig, username)
      setSelectedRowUpdate(undefined)
    } else if (SelectedRowAdd !== undefined) {
      PLOptionAdd(SelectedRowAdd, OptionPLConfig, username)
      setSelectedRowAdd(undefined)
    } else if (SelectedRowDelete !== undefined) {

      console.log("delete")
      console.log(SelectedRowDelete.id)
      firebase.database().ref(OptionPLConfig + '/' + username + "/" + year + "/" + month + "/" + SelectedRowDelete.id).set(null)
      setSelectedRowDelete(undefined)
    }
  }
  return (
    <>
      <MaterialTable
        icons={tableIcons}
        title="Option PL"
        columns={columnOptionData}
        data={OptionPLData}
        options={{
          exportButton: true,
          paging: true,
          pageSize: 10,
          pageSizeOptions: [1, 5, 10, 20, 50, 100],
          rowStyle: rowData => {
            if ((rowData.SellPrice - rowData.BuyPrice) > 0) {
              return { backgroundColor: "#c4ffd6" }
            } else if ((rowData.SellPrice - rowData.BuyPrice) < 0) {
              return { backgroundColor: "#ff9c9c" }
            } else {
              return { backgroundColor: "#ffffff" }
            }
          },
          headerStyle: {
            backgroundColor: '#0099cc',
            color: '#ffffff',
            textColor: '#ffffff',
          }
        }}
        editable={{
          onRowAdd: newData =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                setSelectedRowAdd(newData)
                setOptionPLData([...OptionPLData, newData]);
                resolve();
              }, 1000)
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                const dataUpdate = [...OptionPLData];
                const index = oldData.tableData.id;
                dataUpdate[index] = newData;
                setOptionPLData([...dataUpdate]);
                setSelectedRowUpdate(dataUpdate[index])
                resolve();
              }, 1000)
            }),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                const dataDelete = [...OptionPLData];
                const index = oldData.tableData.id;
                const dataRemove = dataDelete.splice(index, 1);
                setOptionPLData([...dataDelete]);
                setSelectedRowDelete(dataRemove[0])
                resolve()
              }, 1000)
            }),
        }}
      />
    </>
  );
}

export default OptionsPL;
