import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: 36
    },
    billTo: {
        marginTop: 20,
        paddingBottom: 3,
        fontFamily: 'Helvetica-Oblique'
    },
});


const BillTo = (OptionIdea) => (
    <View style={styles.headerContainer}>
        <Text style={styles.billTo}>Bill To:</Text>
        <Text>ABC</Text>
        <Text>123 Main St</Text>
        <Text>Houston, TX 77044</Text>
        <Text>000-000-0000</Text>
    </View>
);

export default BillTo