/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import {
    Card, CardText, CardBody,FormGroup,
    CardTitle, CardSubtitle, Button, Col, Row, Form, Input,
    InputGroupAddon, InputGroupText, InputGroup, CustomInput
} from 'reactstrap';

export class Settings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            layoutWidth: 0,
            layoutHeight: 0,
            autosizeWidth: true,
            autosizeHeight: true,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSaveChanges = this.handleSaveChanges.bind(this);
    }
    handleChange = event => {
        console.log(event.target.id);
        console.log(event.target.value);
        this.setState({ [event.target.id]: event.target.value });
    };
    handleSaveChanges = event => {
        event.preventDefault();
        if (this.state.autosizeHeight === true && this.state.autosizeWidth === true) {
            localStorage.setItem('width', "100%")
            localStorage.setItem('height', "100%")
        } else if (this.state.autosizeHeight === false && this.state.autosizeWidth === false) {
            localStorage.setItem('width', this.state.layoutWidth)
            localStorage.setItem('height', this.state.layoutHeight)
        } else if (this.state.autosizeHeight === true && this.state.autosizeWidth === false) {
            localStorage.setItem('width', this.state.layoutWidth)
            localStorage.setItem('height', "100%")
        } else if (this.state.autosizeHeight === false && this.state.autosizeWidth === true) {
            localStorage.setItem('width', "100%")
            localStorage.setItem('height', this.state.layoutHeight)
        }
    }
    render() {
        return (
            <>
            
                <Card>
                    <CardBody>
                        <CardTitle tag="h3">Layout Width & Height</CardTitle>
                        <CardSubtitle tag="h6" className="mb-2 text-muted">
                            Input Dimension of Charts & Other Layout
                        </CardSubtitle>
                        <CardText>
                            <Form role="form" onSubmit={this.handleSaveChanges}>
                            <FormGroup>
                                <Row>
                                    <Col md={6}>
                                        <InputGroup >
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <b>Width </b>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                //placeholder="Layout Width"
                                                type="number"
                                                id="layoutWidth"
                                                onChange={(this.handleChange)}
                                                //value={localStorage.getItem('width')}
                                                readOnly={this.state.autosizeWidth ? true : false}
                                            />
                                        </InputGroup>
                                        <CustomInput
                                            type="checkbox"
                                            id="autosizeWidth"
                                            onChange={e => this.setState({ autosizeWidth: e.target.checked })}
                                            //value={this.state.autosizeWidth}
                                            label="Auto Size Width" inline />
                                    </Col>
                                    <Col md={6}>
                                        <InputGroup >
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <b>Height </b>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                               placeholder="Layout Height"
                                                type="number"
                                                id="layoutHeight"
                                                
                                                onChange={(this.handleChange)}
                                                readOnly={this.state.autosizeHeight ? true : false}
                                            />
                                        </InputGroup>
                                        <CustomInput
                                            type="checkbox"
                                            id="autosizeHeight"
                                            onChange={e => this.setState({ autosizeHeight: e.target.checked })}
                                            //value={this.state.autosizeHeight}
                                            label="Auto Size Height" inline />
                                    </Col>
                                </Row>
                                </FormGroup>
                                <div className="text-center">
                                    <Button className="my-4" color="info" type="submit">
                                        Save Changes
                                    </Button>
                                </div>
                            </Form>
                        </CardText>
                    </CardBody>
                </Card>
            </>
        );
    }
}
export default Settings;
