/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import CardsHeader from "components/Headers/CardsHeader.js";
import "firebase/auth";
import 'firebase/firestore';
import "firebase/database";
import {
  Card,
  Container,
} from "reactstrap";
import WidgetBot from '@widgetbot/react-embed'

function Education() {

  return (
    <>
      <CardsHeader />
      <br /><br /><br />
      <Container className="mt--6" fluid>
        <Card>
          <WidgetBot
            server="953039139660521502"
            channel="953039378349953024"
            height={window.innerHeight * 0.75}
          />
        </Card>
      </Container>
    </>
  );
}

export default Education;
