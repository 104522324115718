/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Card, CardText, CardBody, CardTitle, CardSubtitle
} from 'reactstrap';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const forexList = [
    "EUR",
    "USD",
    "JPY",
    "GBP",
    "CHF",
    "AUD",
    "CAD",
    "NZD",
    "CNY",
    "TRY",
    "SEK",
    "NOK",
    "DKK",
    "ZAR",
    "HKD",
    "SGD",
    "THB",
    "MXN",
    "IDR",
    "KRW",
    "PLN",
    "ISK",
    "KWD",
    "PHP",
    "MYR",
    "INR",
    "TWD",
    "SAR",
    "AED",
    "RUB",
    "ILS",
    "ARS",
    "CLP",
    "COP",
    "PEN",
    "UYU"
];
export function SelectForexView() {
    const classes = useStyles();
    const [forexName, setForexName] = React.useState(["EUR", "USD", "JPY", "GBP", "CHF", "AUD", "CAD", "NZD", "CNY"]);
    const handleChange = (event) => {
        setForexName(event.target.value);
    };
    localStorage.setItem('forexList', forexName)
    return (
        <>
            <Card>
                <CardBody>
                    <CardTitle tag="h3">Select Forex Symbol from List</CardTitle>
                    <CardSubtitle tag="h6" className="mb-2 text-muted">
                        Default symbol is selected
                    </CardSubtitle>
                    <CardText>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-mutiple-checkbox-label">Select from List</InputLabel>
                            <Select
                                labelId="demo-mutiple-checkbox-label"
                                id="demo-mutiple-checkbox"
                                multiple
                                value={forexName}
                                onChange={handleChange}
                                input={<Input />}
                                renderValue={(selected) => (
                                    <div className={classes.chips}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value} className={classes.chip} />
                                        ))}
                                    </div>
                                )}
                                MenuProps={MenuProps}
                            >
                                {forexList.map((name) => (
                                    <MenuItem key={name} value={name}>
                                        <Checkbox checked={forexName.indexOf(name) > -1} />
                                        <ListItemText primary={name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </CardText>
                </CardBody>
            </Card>
        </>
    );
}
export default SelectForexView;
