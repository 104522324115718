/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import CardsHeader from "components/Headers/CardsHeader.js";
import "firebase/auth";
import 'firebase/firestore';
import "firebase/database";
import ScreenerWidget from "views/adminUI/tradingViewWidget/ScreenerWidget";

function CryptoMarketData() {

  return (
    <>
      <CardsHeader />
      <ScreenerWidget
        selectMarket={"crypto"}
        defaultColumnData={"overview"}
        defaultScreenData={"general"}
      />
    </>
  );
}

export default CryptoMarketData;
