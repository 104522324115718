/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import TickerWidget from "views/adminUI/tradingViewWidget/TickerWidget";
import { SetDataLocalStorage } from "views/adminUI/CustomComponent/SetDataLocalStorage";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
} from "reactstrap";
function CardsHeader({ name, parentName }) {
  return (
    <>
      <TickerWidget />
      {/*<TickerTapeWidget />*/}
      <SetDataLocalStorage />
    </>
  );
}

CardsHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default CardsHeader;
