import { Fragment } from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import { dateFormatMMDDYYYY } from '../../../views/adminUI/CustomComponent/reuseableFn';

const styles = StyleSheet.create({
    OptionIdeaNoContainer: {
        flexDirection: 'row',
        marginTop: 36,
        marginRight:20,
        justifyContent: 'flex-end'
    },
    OptionIdeaDateContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginRight:20,
    },
    OptionIdeaDate: {
            fontSize: 12,
            fontStyle: 'bold',
            marginRight:20,
    },
    label: {
        width: 100
    }
    
  });


  const OptionIdeaNo = (OptionIdea) => {
      //console.log(OptionIdea['OptionIdea'])
      
    return(
        <Fragment>
            <View style={styles.OptionIdeaNoContainer}>
                <Text style={styles.label}>OptionIdea No:</Text>
                <Text style={styles.OptionIdeaDate}>{OptionIdea['OptionIdea'].OptionIdeaNumber}</Text>
            </View >
            <View style={styles.OptionIdeaDateContainer}>
                <Text style={styles.label}>Order Date: </Text>
                <Text style={styles.OptionIdeaDate}>{dateFormatMMDDYYYY(OptionIdea['OptionIdea'].createdDateUtc)}</Text>
            </View >
        </Fragment>
  )};
  
  export default OptionIdeaNo