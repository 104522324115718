import React from 'react';
export function SetDataLocalStorage() {
    if (localStorage.getItem('forexList') === null) {
        localStorage.setItem("forexList", ["EUR", "USD", "JPY", "GBP", "CHF", "AUD", "CAD", "NZD", "CNY"])
    }
    if (localStorage.getItem('height') === null) {
        localStorage.setItem('width', "100%")
    }
    if (localStorage.getItem('height') === null) {
        localStorage.setItem('height', "100%")
    }
    return (
        <>
          
        </>
    )
}
export default SetDataLocalStorage;