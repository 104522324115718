import React, { forwardRef } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

export const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} style={{ color: "green" }} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} style={{ color: "red" }} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} style={{ color: "green" }} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} style={{ color: "orange" }} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};
export function dateFormatMMDDYYYY(data) {
    let newDate = new Date(data)
    let date = newDate.getDate();
    let month = newDate.getMonth() < 11 ? newDate.getMonth() + 1 : 12
    let year = newDate.getFullYear();

    return month + '/' + date + '/' + year
}

export function getYesterdayDate() {
    let newDate = new Date()
    let date = newDate.getDate() - 1;
    let month = newDate.getMonth() < 11 ? newDate.getMonth() + 1 : 12
    let year = newDate.getFullYear();

    return month + '/' + date + '/' + year
}
export default dateFormatMMDDYYYY;

export function formatMonth(data) {
    //console.log(data)
    if (data > 11) {
        return data + 1;
    } else if (data === 11) {
        return 12;
    }
}
export const adminUserList = ["tradinganish"]


export function UndefinedReturnNull(value) {
    if (value === undefined) {
        return null
    } else {
        return value
    }
}
export function UndefinedReturnZero(value) {
    if (value === undefined) {
        return 0
    } else {
        return value
    }
}

export function UndefinedReturnTodayDate(value) {
    if (value === undefined) {
        return new Date()
    } else {
        return value
    }
}

export function FormatDateMMDDYYY(dateReceived) {
    let formatDate = new Date(dateReceived);
    let date = formatDate.getDate();
    let month = formatDate.getMonth() + 1;
    let year = formatDate.getFullYear();
    return month + "/" + date + "/" + year
}


export const useStyles = makeStyles((theme) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "fit-content",
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
    root: {
        background: "linear-gradient(45deg, #5AB4FB 30%, #289FFC 90%)",
        borderRadius: 3,
        border: 0,
        height: 48,
        padding: "0 10px",
        boxShadow: "0 3px 5px 2px rgba(4, 116, 204, .3)",
    },
    label: {
        color: "#ffffff",
        textTransform: "capitalize",
    },
}));

export function currencyFormat(num) {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export function subtotal(props) {
    return props.lineItems.map(a => a.lineItemTotalCost).reduce((sum, i) => sum + i, 0);
}