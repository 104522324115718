/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// reactstrap components
import {
    Collapse,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Navbar,
    NavItem,
    Nav,
    Container,
} from "reactstrap"
import { Link } from "react-router-dom";
import handleLogout from 'views/authUI/Logout';
import TimelineWidget from "views/adminUI/tradingViewWidget/TimelineWidget";
import TechnicalAnalysisWidget from "views/adminUI/tradingViewWidget/TechnicalAnalysisWidget";
import EconomicCalenderWidget from "views/adminUI/tradingViewWidget/EconomicCalenderWidget";
import { FormatDateMMDDYYY } from "views/adminUI/CustomComponent/reuseableFn";
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
function AdminNavbar({ theme, sidenavOpen, toggleSidenav }) {
    var selectedDate = localStorage.getItem("selectedDate") === null ? FormatDateMMDDYYY(new Date()) : FormatDateMMDDYYY(localStorage.getItem("selectedDate"));
    // function that on mobile devices makes the search open
    const openSearch = () => {
        document.body.classList.add("g-navbar-search-showing");
        setTimeout(function () {
            document.body.classList.remove("g-navbar-search-showing");
            document.body.classList.add("g-navbar-search-show");
        }, 150);
        setTimeout(function () {
            document.body.classList.add("g-navbar-search-shown");
        }, 300);
    };
    // function that on mobile devices makes the search close
    const closeSearch = () => {
        document.body.classList.remove("g-navbar-search-shown");
        setTimeout(function () {
            document.body.classList.remove("g-navbar-search-show");
            document.body.classList.add("g-navbar-search-hiding");
        }, 150);
        setTimeout(function () {
            document.body.classList.remove("g-navbar-search-hiding");
            document.body.classList.add("g-navbar-search-hidden");
        }, 300);
        setTimeout(function () {
            document.body.classList.remove("g-navbar-search-hidden");
        }, 500);
    };
    var username = localStorage.getItem("email") === null ? 'kingswayanish' : localStorage.getItem("email").split('@')[0].replace(/[&\\#,+()$~%.'":*?<>{}]/, '');
    return (
        <>
            <Navbar
                className={classnames(
                    "navbar-top navbar-expand border-bottom",
                    { "navbar-dark bg-info": theme === "dark" },
                    { "navbar-light bg-secondary": theme === "light" }
                )}
            >
                <Container fluid>
                    <Collapse navbar isOpen={true}>
                        <Nav className="align-items-center ml-md-auto" navbar>
                            <NavItem className="d-xl-none">
                                <div
                                    className={classnames(
                                        "pr-3 sidenav-toggler",
                                        { active: sidenavOpen },
                                        { "sidenav-toggler-dark": theme === "dark" }
                                    )}
                                    onClick={toggleSidenav}
                                >
                                    <div className="sidenav-toggler-inner">
                                        <i className="sidenav-toggler-line" />
                                        <i className="sidenav-toggler-line" />
                                        <i className="sidenav-toggler-line" />
                                    </div>
                                </div>
                            </NavItem>
                          
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    //disableFuture
                                    label="Date"
                                    inputFormat="MM/dd/yyyy"
                                    value={selectedDate}
                                    onChange={(newValue) => {
                                        localStorage.setItem('selectedDate', FormatDateMMDDYYY(newValue))
                                        window.location.reload()
                                    }}
                                    renderInput={(params) => <TextField {...params} style={{ color: '#ffffff', }} />}
                                />
                            </LocalizationProvider>
                            <UncontrolledDropdown nav>
                                <DropdownToggle className="nav-link" color="" tag="a">
                                    <i className="ni ni-calendar-grid-58" /> Economic Calender
                                </DropdownToggle>
                                <DropdownMenu
                                    className="dropdown-menu-xl py-0 overflow-hidden"
                                    right
                                >
                                    <EconomicCalenderWidget />
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <UncontrolledDropdown nav>
                                <DropdownToggle className="nav-link" color="" tag="a">
                                    <i className="fas fa-chart-area" /> Stock Rating
                                </DropdownToggle>
                                <DropdownMenu
                                    className="dropdown-menu-xl py-0 overflow-hidden"
                                    right
                                >
                                    <TechnicalAnalysisWidget />
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <UncontrolledDropdown nav>
                                <DropdownToggle className="nav-link" color="" tag="a">
                                    <i className="fas fa-calendar-day" /> Events
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-lg dropdown-menu-dark" right>
                                    <TimelineWidget
                                        selectFeedMode={"all_symbols"}
                                        defaultSymbol={null}
                                    />
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                        <Nav className="align-items-center ml-auto ml-md-0" navbar>
                            <UncontrolledDropdown nav>
                                <DropdownToggle className="nav-link pr-0" color="" tag="a">
                                    <Media className="align-items-center">
                                        <span className="avatar avatar-sm rounded-circle">
                                            {/* <img
                        alt="..."
                        src={require("assets/img/theme/team-4.jpg").default}
                     />*/}A
                                        </span>
                                        <Media className="ml-2 d-none d-lg-block">
                                            <span className="mb-0 text-sm font-weight-bold">
                                                {username}
                                            </span>
                                        </Media>
                                    </Media>
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem className="noti-title" header tag="div">
                                        <h6 className="text-overflow m-0">Welcome!</h6>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <NavItem to="/admin/profile" tag={Link}>
                                            <i className="fas fa-user"></i>
                                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Profile</span>
                                        </NavItem>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <NavItem to="/admin/settings" tag={Link}>
                                            <i className="fas fa-cog"></i>
                                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Settings</span>
                                        </NavItem>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <NavItem to="/admin/support" tag={Link}>
                                            <i className="fas fa-ticket-alt"></i>
                                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Support</span>
                                        </NavItem>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <NavItem to="/admin/aggrement" tag={Link}>
                                            <i className="fas fa-file-contract"></i>
                                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Aggrement</span>
                                        </NavItem>
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem>
                                        <NavItem to="/" onClick={handleLogout} tag={Link}>
                                            <i className="fas fa-sign-out-alt"></i>
                                            <span onClick={handleLogout}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Logout</span>
                                        </NavItem>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                    </Collapse>
                </Container>
            </Navbar>
        </>
    );
}

AdminNavbar.defaultProps = {
    toggleSidenav: () => { },
    sidenavOpen: false,
    theme: "dark",
};
AdminNavbar.propTypes = {
    toggleSidenav: PropTypes.func,
    sidenavOpen: PropTypes.bool,
    theme: PropTypes.oneOf(["dark", "light"]),
};

export default AdminNavbar;
