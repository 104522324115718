/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Alert
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import firebase from "firebase/app";

class Register extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      emailNewAccount: '',
      passwordNewAccount: '',
      errorCreatAccountDetails: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleCreateNewAccount = this.handleCreateNewAccount.bind(this);
  }
  handleChange = event => {
    //console.log(event.target.id);
    // console.log(event.target.value);
    this.setState({ [event.target.id]: event.target.value });
  };
  handleCreateNewAccount = event => {
    event.preventDefault();
    firebase.auth().createUserWithEmailAndPassword(this.state.emailNewAccount, this.state.passwordNewAccount)
      .then((userCredential) => {
        // Signed in 
        //var user = userCredential.user;
        localStorage.setItem('email', userCredential.user.email)
        this.props.history.push("/admin/optionflow");
      })
      .catch((error) => {
        this.setState({
          errorCreatAccountDetails: error.message
        })
        console.log(error)
      });
  }
  render() {
    return (
      <>
      <br/>
        <AuthHeader
          title="Welcome!"
          lead="Sign up to start receiving our serives!"
        />
        <br/>
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary border-0 mb-0">
                <CardHeader className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <small> Create New Account</small>
                  </div>
                  <Form role="form" onSubmit={this.handleCreateNewAccount}>
                    <FormGroup>
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Email"
                          type="email"
                          id="emailNewAccount"
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Password"
                          type="password"
                          id="passwordNewAccount"
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                    </FormGroup>

                    {this.state.errorCreatAccountDetails !== null ?
                      <div><br></br>
                        <Alert color="danger">
                          {this.state.errorCreatAccountDetails}
                        </Alert>
                      </div> : null}
                    <Row className="my-4">
                      <Col xs="12">
                        <div className="custom-control custom-control-alternative custom-checkbox">
                          <input
                            className="custom-control-input"
                            id="customCheckRegister"
                            type="checkbox"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheckRegister"
                          >
                            <span className="text-muted">
                              I agree with the{" "}
                              <a
                                href="#pablo"
                              // onClick={(e) => e.preventDefault()}
                              >
                                Privacy Policy
                              </a>
                            </span>
                          </label>
                        </div>
                      </Col>
                    </Row>
                    <div className="text-center">
                      <Button className="my-4" color="info" type="submit">
                        Submit
                      </Button>
                    </div>
                  </Form>
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }

}

export default Register;
