import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { subtotal, currencyFormat } from '../../../views/adminUI/CustomComponent/reuseableFn';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontSize: 12,
        fontStyle: 'bold',
    },
    description: {
        width: '85%',
        textAlign: 'right',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingRight: 8,
    },
    total: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
});


const OptionIdeaTableFooter = (items) => {
    //console.log(items['items'])
    const OptionIdeaSubtotal = subtotal(items['items']);
    const OptionIdeaTaxes = items['items'].taxAmount;
    const OptionIdeaTotal = OptionIdeaTaxes + OptionIdeaSubtotal;
    return (
        <>
            <View style={styles.row}>
                <Text style={styles.description}>Tax</Text>
                <Text style={styles.total}>{ currencyFormat(OptionIdeaTaxes)}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.description}>Total Amount</Text>
                <Text style={styles.total}>{currencyFormat(OptionIdeaTotal)} </Text>
            </View>
        </>
    )
};

export default OptionIdeaTableFooter