import { View, StyleSheet, Text } from '@react-pdf/renderer';
import OptionIdeaTableRow from './OptionIdeaTableRow'
//import OptionIdeaTableBlankSpace from './OptionIdeaTableBlankSpace'
import OptionIdeaTableFooter from './OptionIdeaTableFooter'

//const tableRowsCount = 11;
const borderColor = '#90e5fc'

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        backgroundColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: '#bff0fd',
    },
    row: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
        color: 'white'
    },
    description: {
        width: '35%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    qty: {
        width: '50%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
});




const OptionIdeaItemsTable = ({OptionIdea}) => {
    console.log(OptionIdea)
    return (
        <View style={styles.tableContainer}>
            <OptionIdeaTableHeader />
            <OptionIdeaTableRow items={OptionIdea} />
            {/*<OptionIdeaTableBlankSpace rowsCount={tableRowsCount - OptionIdea.OptionIdea['OptionIdea'].length} />
            <OptionIdeaTableFooter items={OptionIdea['OptionIdea']} />*/}
        </View>
    )
};

export default OptionIdeaItemsTable

const OptionIdeaTableHeader = () => (
    <View style={styles.container}>
        <Text style={styles.description}>Symbol</Text>
        <Text style={styles.description}>Type</Text>
        <Text style={styles.description}>Strike</Text>
        <Text style={styles.description}>Exp Date</Text>
        <Text style={styles.description}>Volume</Text>
        <Text style={styles.description}>OI</Text>
        <Text style={styles.description}>Premium</Text>
        <Text style={styles.qty}>Message</Text>
    </View>
);


