/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import "firebase/auth";
import 'firebase/firestore';
import "firebase/database";
// If widgets props are missing, use widgetPropsAny
export class TickerWidget extends React.Component {

  
  componentDidMount() {
  const script = document.createElement('script');
  script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-tickers.js'
  script.async = true;
  script.innerHTML = JSON.stringify(
    {
      "symbols": [
        {
          "proName": "FOREXCOM:SPXUSD",
          "title": "S&P 500"
        },
        {
          "description": "Dow",
          "proName": "FOREXCOM:DJI"
        },
        {
          "proName": "FOREXCOM:NSXUSD",
          "title": "Nasdaq 100"
        },
        {
          "description": "Russle 2000",
          "proName": "FOREXCOM:US2000"
        },
        {
          "proName": "BITSTAMP:BTCUSD",
          "title": "BTC/USD"
        },
        {
          "description": "Gold",
          "proName": "TVC:GOLD"
        },
        {
          "description": "Crude Oil",
          "proName": "TVC:USOIL"
        }
      ],
      "colorTheme": "dark",
      "isTransparent": false,
      "showSymbolLogo": true,
      "locale": "en"
    }
  )
  document.getElementById("myContainerTickerWidget").appendChild(script);
}
render() {
  return (
    <>
      <div id="myContainerTickerWidget">
        <div className="tradingview-widget-container">
          <div className="tradingview-widget-container__widget">
          </div>
        </div>
      </div>
      <div class="tradingview-widget-copyright"><a href="https://www.tradingview.com" rel="noreferrer" target="_blank"><span class="blue-text">Quotes</span></a> by TradingView</div>
    </>
  );
}
}
export default TickerWidget;
