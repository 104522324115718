/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import "firebase/auth";
import 'firebase/firestore';
import "firebase/database";
import TradingViewWidget, { Themes } from 'react-tradingview-widget';
import CardsHeader from 'components/Headers/CardsHeader';
// If widgets props are missing, use widgetPropsAny
export class Charts extends React.Component {

  render() {
    return (
      <>

        <CardsHeader />
        <TradingViewWidget
          symbol="NASDAQ:AAPL"
          theme={Themes.DARK}
          locale="en"
          width="100%"
          height={700}
          timezone={"America/Chicago"}
          toolbar_bg={"#f1f3f6"}
          withdateranges={true}
          allow_symbol_change={true}
          hide_side_toolbar={false}
          details={true}
          hotlist={true}
          calendar={true}
          container_id={"tradingview_1490a"}
          watchlist={
            ["AAPL", "MSFT"]
          }
        />
        <div class="tradingview-widget-copyright"><a href="https://www.tradingview.com/symbols/NASDAQ-AAPL/" rel="noreferrer" target="_blank"><span class="blue-text">AAPL Chart</span></a> by TradingView</div>
      </>
    );
  }
}
export default Charts;
