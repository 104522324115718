/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Alert
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import firebase from "firebase/app";
import { FormatDateMMDDYYY } from "views/adminUI/CustomComponent/reuseableFn";

export class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      errorLoginDetails: null,
      onClickForgetPasswordButton: false,
      emailToResetPassword: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmitSignIn = this.handleSubmitSignIn.bind(this);
    this.handleForgetPassword = this.handleForgetPassword.bind(this);
    this.handleForgetPasswordButton = this.handleForgetPasswordButton.bind(this);
  }
  handleChange = event => {
    //console.log(event.target.id);
    // console.log(event.target.value);
    this.setState({ [event.target.id]: event.target.value });
  };


  handleSubmitSignIn = event => {
    event.preventDefault();

    //console.log(this.state.email);
    // console.log(this.state.password);
    firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password)
      .then((userCredential) => {
        console.log(userCredential.user.email)
        localStorage.setItem('email', userCredential.user.email)
        localStorage.setItem('selectedDate', FormatDateMMDDYYY(new Date()))
        //localStorage.setItem("auth", userCredential.operationType);
        //localStorage.setItem("token", userCredential.user.Aa);
        window.location.reload();
        this.props.history.push("/admin/optionflow");
        // window.location.reload();
      })
      .catch((error) => {
        this.setState({
          errorLoginDetails: error.message
        })
        //var errorMessage = error.message;
        // console.log(errorMessage)
      });
  }

  handleForgetPassword = event => {
    event.preventDefault();
    firebase.auth().sendPasswordResetEmail(this.state.emailToResetPassword)
      .then(() => {
        this.props.history.push("/auth/login");
      })
      .catch((error) => {
        this.setState({
          errorLoginDetails: error.message
        })
      });
  }

  handleForgetPasswordButton = () => {
    this.setState({
      onClickForgetPasswordButton: true
    })
  }

  render() {
    return (
      <>
        <AuthHeader
          title="Welcome!"
          lead="Trading Portal"
        />
        <Container className="mt--8 pb-5" >
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary border-0 mb-0">
                {
                  this.state.onClickForgetPasswordButton === false ?
                    <>
                      <CardHeader className="px-lg-5 py-lg-5">
                        <div className="text-center text-muted mb-4">
                          <small> Sign In with Credentials</small>
                        </div>
                        <Form role="form" onSubmit={this.handleSubmitSignIn}>
                          <FormGroup>
                            <InputGroup className="input-group-merge input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-email-83" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Email"
                                type="email"
                                id="email"
                                onChange={this.handleChange}
                              />
                            </InputGroup>
                          </FormGroup>
                          <FormGroup>
                            <InputGroup className="input-group-merge input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-lock-circle-open" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Password"
                                type="password"
                                id="password"
                                onChange={this.handleChange}
                              />
                            </InputGroup>
                          </FormGroup>
                          <div className="custom-control custom-control-alternative custom-checkbox">
                            <input
                              className="custom-control-input"
                              id=" customCheckLogin"
                              type="checkbox"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor=" customCheckLogin"
                            >
                              <span className="text-muted">Remember me</span>
                            </label>
                          </div>
                          {this.state.errorLoginDetails !== null ?
                            <div><br></br>
                              <Alert color="danger">
                                {this.state.errorLoginDetails}
                              </Alert>
                            </div> : null}
                          <div className="text-center">
                            <Button className="my-4" color="info" type="submit">
                              Sign in
                            </Button>
                          </div>
                        </Form>
                      </CardHeader>
                    </> :
                    <>
                      <CardHeader className="px-lg-5 py-lg-5">
                        <div className="text-center text-muted mb-4">
                          <small> Enter your email to reset passoword</small>
                        </div>
                        <Form role="form" onSubmit={this.handleForgetPassword}>
                          <FormGroup>
                            <InputGroup className="input-group-merge input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-email-83" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Email"
                                type="email"
                                id="emailToResetPassword"
                                onChange={(this.handleChange)}
                              />
                            </InputGroup>
                          </FormGroup>
                          {this.state.errorLoginDetails !== null ?
                            <div><br></br>
                              <Alert color="danger">
                                {this.state.errorLoginDetails}
                              </Alert>
                            </div> : null}
                          <div className="text-center">
                            <Button className="my-4" color="info" type="submit">
                              Submit
                            </Button>
                          </div>
                        </Form>
                      </CardHeader>
                    </>
                }
              </Card>
              <Row className="mt-3">
                <Col xs="6">
                  {this.state.onClickForgetPasswordButton === false ?
                    <>
                      <a
                        className="text-light"
                        href="#pablo"
                        onClick={this.handleForgetPasswordButton}
                      >
                        <small>Forgot password?</small>
                      </a>
                    </> :
                    <>
                      <a
                        className="text-light"
                        href="#pablo"
                        onClick={() => { this.setState({ onClickForgetPasswordButton: false }) }}
                      >
                        <small>Login?</small>
                      </a>
                    </>
                  }

                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
export default Login;
