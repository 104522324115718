import { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { currencyFormat } from '../../../views/adminUI/CustomComponent/reuseableFn';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    row: {
        
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
    },
    description: {
        width: '35%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    qty: {
        width: '50%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
   
});


const OptionIdeaTableRow = (items) => {
    //console.log(items['items'])
    const rows = items['items'].map((item) =>
        <View style={styles.row} key={item.Last.toString()}>
            <Text style={styles.description}>{item.Symbol}</Text>
            <Text style={styles.description}>{item.Type}</Text>
            <Text style={styles.description}>{currencyFormat(item.Strike)}</Text>
            <Text style={styles.description}>{item.ExpDate}</Text>
            <Text style={styles.description}>{item.OptionsVolume}</Text>
            <Text style={styles.description}>{item.OpenInterest}</Text>
            <Text style={styles.description}>{currencyFormat(item.OptionPrice)}</Text>
            <Text style={styles.qty}>{item.Message}</Text>
        </View>
    )
    return (<Fragment>{rows}</Fragment>)
};

export default OptionIdeaTableRow