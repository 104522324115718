/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import {
  Card,
  Container,
  Row,
  Col,
} from "reactstrap";
import CardsHeader from "components/Headers/CardsHeader.js";
import "firebase/auth";
import 'firebase/firestore';
import "firebase/database";
import FundamentalDataWidget from '../tradingViewWidget/FundamentalDataWidget';
import CompanyProfileWidget from '../tradingViewWidget/CompanyProfileWidget';
import MiniChartWidget from '../tradingViewWidget/MiniChartWidget';
import StockMarketWidget from '../tradingViewWidget/StockMarketWidget';
import SymbolInfoWidget from '../tradingViewWidget/SymbolInfoWidget';

function CompanyAnalysis() {

  return (
    <>
      <CardsHeader />
      <br /><br /><br /><br />
      <Container className="mt--6" fluid>
        <Row className="card-wrapper">
          <Col lg="8">
            <Card>
              <SymbolInfoWidget />
            </Card>
          </Col>
          <Col lg="4">
            <Card>
              <MiniChartWidget />
            </Card>
          </Col>
        </Row>
        <Row className="card-wrapper">
          <Col lg="4">
            <Card>
              <FundamentalDataWidget />
            </Card>
          </Col>
          <Col lg="4">
            <Card>
              <CompanyProfileWidget />
            </Card>
          </Col>
          <Col lg="4">
            <Card>
              <StockMarketWidget />
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default CompanyAnalysis;
