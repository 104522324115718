/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import CardsHeader from "components/Headers/CardsHeader.js";
import { orderFlowData } from "./CustomComponent/BackendDataConfig";
import {
  Card,
  Container,
} from "reactstrap";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import MaterialTable from "material-table";
import { tableIcons } from "./CustomComponent/reuseableFn";

export function OptionFlow() {
  const [optionData, setOptionData] = React.useState();
  // column to show on table
  //initialize to get data of all customers, order history
  useEffect(() => {
    const dbRef = firebase.database().ref(orderFlowData);
    dbRef
      .get()
      .then((snapshot) => {
        let users = [];
        if (snapshot.exists()) {
          snapshot.forEach((snap) => {
            users.push(snap.val());
          });
          setOptionData(users);
          //console.log(users);
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const [columnOptionData] = useState([
    { title: "Symbol", field: "Symbol" },
    { title: "Last", field: "Last", type: 'currency' },
    { title: "Change", field: "Change", type: 'currency' },
    { title: "IV Rank", field: "IV Rank" },
    { title: "Option Volume", field: "Options Vol", type: 'numeric' },
    { title: "%Put", field: "% Put" },
    { title: "%Call", field: "% Call" },
  ]);

  return (
    <>

      <CardsHeader />
      <br /><br /><br /><br />
      <Container className="mt--6" fluid>
        <Card>
          <MaterialTable
            icons={tableIcons}
            title="Option Data"
            columns={columnOptionData}
            data={optionData}
            options={{
              exportButton: true,
              paging: true,
              pageSize: 10,
              pageSizeOptions: [1, 5, 10, 20, 50, 100],
              headerStyle: {
                backgroundColor: '#8BCCFE',
                color: '#000000',
                textColor: '#000000',
              }
            }}
          />
        </Card>
      </Container>
    </>
  );
}
export default OptionFlow;
